
import { Vue, Component } from 'vue-property-decorator';
import $ from 'jquery';
import Slick from 'vue-slick';
import { getPostList } from '@/api/post';
import moment from 'moment';
import { IPost } from '@/types';

@Component({
  name: 'AppIndex',
  components: {
    Slick,
  },
})
export default class extends Vue {
  mounted() {
    this.getPostList();
  }

  get imageUrl() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  private eventPostList = [];

  private businessPostList = [];

  private fundPostList = [];

  private competitionPostList = [];

  private noticePostList = [];

  private eventSlickOptions = {
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    infinite: true,
    speed: 500,
    arrows: true,
    draggable: true,
    prevArrow: $('.eve-prev'),
    nextArrow: $('.eve-next'),
  };

  private handleClickSlide(isNext: boolean) {
    const slick = (this.$refs.supportSlideOptions as any);
    if (isNext) slick.next();
    else slick.prev();
  }

  private handleClickTab(num: number) {
    // if (num === 1) this.$router.push({ name: 'AppSupportList', params: { category: 'c5222948-fdc7-41dc-b428-638fd14135db' } });
    if (num === 1) this.$router.push({ name: 'AppSupportList', params: { category: '224e5ccf-fcd8-4395-840f-868e63ab41f6' } });
    // else if (num === 2) this.$router.push({ name: 'AppSupportList', params: { category: '6317f94d-b1d2-49ee-baf6-7bb6cbc5759f' } });
    else if (num === 2) this.$router.push({ name: 'AppSupportList', params: { category: '4e48e473-2792-4483-91ab-9c1ed1000be5' } });
    // else if (num === 3) this.$router.push({ name: 'AppSupportList', params: { category: '5769e4ef-236d-4c02-85e8-d3e5dc0537d8' } });
    else if (num === 3) this.$router.push({ name: 'AppSupportList', params: { category: 'ee204210-11b3-4703-8fbf-95bcfccf2856' } });
  }

  private getPostList() {
    getPostList({ boardUid: '5ad93161-0db7-4cf1-af8c-9e19f83b8abc', size: 3 }).then((res) => {
      this.noticePostList = res.data.content;
    });
    getPostList({ boardUid: 'dc1e2055-0600-42b6-800b-904b18dfcdab', size: 3, categoryList: 'c5222948-fdc7-41dc-b428-638fd14135db' }).then((res) => {
      this.businessPostList = res.data.content;
    });
    getPostList({ boardUid: 'dc1e2055-0600-42b6-800b-904b18dfcdab', size: 3, categoryList: '6317f94d-b1d2-49ee-baf6-7bb6cbc5759f' }).then((res) => {
      this.fundPostList = res.data.content;
    });
    getPostList({ boardUid: 'dc1e2055-0600-42b6-800b-904b18dfcdab', size: 3, categoryList: '5769e4ef-236d-4c02-85e8-d3e5dc0537d8' }).then((res) => {
      this.competitionPostList = res.data.content;
    });
    getPostList({ boardUid: '3e443ca9-92ff-4d50-877b-64f90c4d2c8a', size: 3 }).then((res) => {
      this.eventPostList = res.data.content;
    });
  }

  private getThumnail(thumbnail: string) {
    if (thumbnail.startsWith('http')) return thumbnail;
    return `${this.imageUrl}/${thumbnail}`;
  }

  private isEnd(post: IPost) {
    if (!post.dataList[3].inputValue || !post.dataList[4].inputValue) return '진행중';
    const today = moment().startOf('day');
    const endDay = moment(post.dataList[4].inputValue, 'YYYY-MM-DD');
    if (today.isSameOrBefore(endDay)) {
      const remain = endDay.diff(today, 'days');
      if (remain >= 1) return `마감 ${remain} 일전`;
      if (remain === 0) return '오늘 마감';
      return '오늘 마감';
    }
    return '마감';
  }

  private isEndClass(post: IPost) {
    if (!post.dataList[4].inputValue) return 'state01';
    const today = moment().startOf('day');
    const endDay = moment(post.dataList[4].inputValue, 'YYYY-MM-DD');
    const remain = endDay.diff(today, 'days');
    if (remain >= 30) return 'state03';
    if (remain >= 7) return 'state02';
    if (remain >= 0) return 'state01';
    return 'state00';
  }
}
